<template>
  <div class="search-list-item" :class="[newTheme]" @click="onGoTo">
    <!--收藏图标-->
    <!-- <img v-if="isLogin" class="search-item-icon" :src="showFavorite" alt="" /> -->
    <img alt :src="iconUrl" class="search-item-icon" v-if="iconUrl" />
    <section class="search-currency">
      <div class="search-currency-top">
        <span class="search-symbol top-item"> {{ baseSymbol || '-' }}{{ pricedSymbol || '-' }} </span>
        <Tag v-if="newP" :isNew="true" class="top-item" />
        <Tag v-if="hotP" :isNew="false" class="top-item" />
        <span v-if="makerFeeRate === 0 && takerFeeRate === 0 && isPro" class="search-maker top-item">
          {{ $t('new_contract.trade.0_free') }}
        </span>
      </div>
      <div v-if="status === 1 && volumeText && !isSpot" class="search-volume">{{ volumeText }}</div>
    </section>
    <section v-show="status === 1 || isSpot" class="search-mark-price">
      {{ showPrice() }}
    </section>
    <section
      v-show="status === 1 || isSpot"
      class="search-change-text"
      :class="[+change < 0 ? 'fall' : +change === 0 ? '' : 'rise']"
    >
      {{ showChange() }}
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Tag from './Tag.vue';
export default {
  props: {
    // isPro: {
    //   type: Boolean,
    //   default: false
    // },
    favorite: {
      type: Boolean,
      default: false
    },
    iconUrl: {
      type: String,
      default: ''
    },
    newP: {
      type: Boolean,
      default: false
    },
    hotP: {
      type: Boolean,
      default: false
    },
    baseSymbol: {
      type: String,
      default: ''
    },
    pricedSymbol: {
      type: String,
      default: ''
    },
    takerFeeRate: {
      type: Number,
      default: 0
    },
    makerFeeRate: {
      type: Number,
      default: 0
    },
    status: {
      type: Number,
      default: -1
    },
    volumeText: {
      type: String,
      default: ''
    },
    price: [Number, String],
    markPrice: [Number, String],
    changeText: {
      type: String,
      default: ''
    },
    change: [String, Number],
    contractStatus: {
      type: Number,
      default: 1
    },
    productCode: {
      type: String,
      default: ''
    },
    redictPath: String
  },
  components: {
    Tag
  },
  computed: {
    ...mapGetters(['isLogin']),
    ...mapState(['newTheme']),
    showFavorite() {
      return this.favorite ? require('../../assets/img/favorite.svg') : require('../../assets/img/favorite.svg');
    },
    isPro() {
      return this.redictPath === 'swapnew/';
    },
    isSpot() {
      return this.redictPath === 'trade/';
    }
  },
  methods: {
    onGoTo() {
      // if (!this.isPro&&this.contractStatus !== 1) {
      //   return;
      // }

      // const redictPath = this.isPro ? 'swapnew/' : 'swap/';
      if (window.IEVersion && window.IEVersion !== -1) {
        window.location.href =
          window.location.origin + '/' + this.$i18n.locale + '/' + this.redictPath + this.productCode;
        return;
      }
      this.$router.push(this.$i18n.path(this.redictPath + this.productCode));
    },
    showPrice() {
      return this.isPro ? this.markPrice : this.price;
    },
    formatChange(value) {
      let number = value;
      if (isNaN(value) || Number(value) === 0) {
        number = 0;
      }
      number = Number(value) > 0 ? '+' + value : number;
      return number + '%';
    },
    formatProChange() {
      if (this.change > 0) {
        return `+${this.changeText}`;
      }
      return this.changeText;
    },
    showChange() {
      return this.isPro ? this.formatProChange() : this.formatChange(this.change);
    }
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.search-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  cursor: pointer;
  border-radius: 16px;
  padding: 0 8px;
  &:hover {
    background-color: @black-bg-tertiary;
  }
  @icon-size: 16px;
  .search-item-icon {
    width: @icon-size;
    height: @icon-size;
  }
  .search-item-icon + .search-item-icon {
    margin-left: 8px;
  }
  .search-currency {
    margin-left: 8px;
    flex-grow: 1;
    .search-currency-top {
      display: flex;
      .top-item + .top-item {
        margin-left: 4px;
      }
      .search-symbol {
        font-size: 14px;
        font-weight: 500;
        .medium-font();
        color: @black-text-primary;
      }
      .search-maker {
        padding: 0px 4px 0px 4px;
        height: 16px;
        line-height: 16px;
        border-radius: 4px;
        text-align: center;
        color: #f8c71b;
      }
    }
  }

  .search-volume {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: @black-text-tertiary;
  }
  .search-change-text {
    width: 100px;
    font-size: 14px;
    font-weight: 500;
    .medium-font();
    text-align: right;
    color: @black-text-secondary;
    flex-shrink: 0;
  }
  .search-mark-price {
    width: 120px;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    .medium-font();
    color: @black-text-secondary;
    flex-shrink: 0;
  }
  .fall {
    font-size: 14px;
    font-weight: 500;
    .medium-font();
    color: @red-color !important;
  }
  .rise {
    font-size: 14px;
    font-weight: 500;
    .medium-font();
    color: @green-color !important;
  }
}
.white {
  .search-list-item {
    &:hover {
      background-color: @white-bg-tertiary;
    }

    .search-currency {
      .search-currency-top {
        .search-symbol {
          color: @white-text-primary;
        }
      }
    }

    .search-volume {
      color: @white-text-tertiary;
    }

    .search-mark-price {
      color: @white-text-secondary;
    }
    .search-change-text {
      color: @white-text-secondary;
    }
  }
}
</style>