<template>
  <div class="currency-tag red-bg" :class="{ 'green-bg': isNew }">{{ isNew ? 'New' : 'Hot' }}</div>
</template>

<script>
export default {
  props: ['isNew']
};
</script>

<style lang="less" scoped>
.currency-tag {
  padding: 2px 4px;
  .bold-font();
  font-size: 10px;
  font-weight: 700;
  color: @always-white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.red-bg {
  background-color: @red-color;
}
.green-bg {
  background-color: @green-color;
}
</style>