<template>
  <div class="no-data-block">
    <img alt="" :src="showImg" class="no-data-img" />
    <section class="no-data-text">{{ searchText ? $t('index.no_result') : $t('index.search_now') }}</section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['searchText'],
  computed: {
    ...mapState(['newTheme']),
    showImg() {
      return this.newTheme === 'black'
        ? require('../../assets/img/no-data.svg')
        : require('../../assets/img/no-data-white.svg');
    }
  }
};
</script>

<style lang="less" scoped>
.no-data-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  .no-data-img {
    width: 56px;
    height: 56px;
    border-radius: 36px;
    margin-bottom: 16px;
  }
  .no-data-text {
    font-size: 12px;
    font-weight: 400;
    color: @black-text-tertiary;
  }
}
</style>