<template>
  <transition name="hm-message-fade">
    <div :class="[
      'hm-message',
      type ? `hm-${type}`: '',
      customClass
    ]"
         v-show="visible"
         role="alert">
      <i :class="iconClass" v-if="iconClass"></i>
      <i :class="typeClass" v-else></i>
      <slot>
        <p class="hm-message__content" v-html="message"></p>
      </slot>
    </div>
  </transition>
</template>

<script>
  const typeMap = {
    success: 'zhengque',
    info: '',
    warning: 'alert',
    error: 'cuowu'
  };

  export default {
    name: "GlMessage",
    data() {
      return {
        visible: false,
        message: '',
        duration: 3000, // 只显示3秒
        type: 'info',
        iconClass: '',
        customClass: '',
        onClose: null,
        showClose: false,
        closed: false,
        timer: null,
        closeOnHashChange: true
      }
    },
    computed: {
      typeClass() {
        return this.type && !this.iconClass && typeMap[this.type]
          ? `hm-icon ${typeMap[this.type]}`
          : '';
      }
    },
    mounted() {
      this.startTimer();
      document.addEventListener('keydown', this.keydown);

      this.$nextTick(() => {
        if (this.closeOnHashChange) {
          window.addEventListener('hashchange', this.close);
        }
      });
    },
    beforeDestroy() {
      if (this.closeOnHashChange) {
        window.removeEventListener('hashchange', this.close);
      }
      document.removeEventListener('keydown', this.keydown);
    },
    methods: {
      destroyElement() {
        this.$el.removeEventListener('transitionend', this.destroyElement);
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      },

      close() {
        this.closed = true;
        if (typeof this.onClose === 'function') {
          this.onClose(this);
        }
      },

      clearTimer() {
        clearTimeout(this.timer);
      },

      startTimer() {
        if (this.duration > 0) {
          this.timer = setTimeout(() => {
            if (!this.closed) {
              this.close();
            }
          }, this.duration);
        }
      },
      keydown(e) {
        if (e.keyCode === 27) { // esc关闭消息
          if (!this.closed) {
            this.close();
          }
        }
      }
    },
    watch: {
      closed(newVal) {
        if (newVal) {
          this.visible = false;
          this.$el.addEventListener('transitionend', this.destroyElement);
        }
      }
    },
  }
</script>

<style lang="less">
  .hm-message {
    min-width: 100px;
    box-sizing: border-box;
    border-radius: 4px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    padding:14px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s, transform .4s;
     z-index: 5000;
    background: #282E3D;
    box-shadow: 0 1px 4px 0 rgba(20,22,38,0.76);

    .hm-icon {
      margin-right: 6px;
        width: 20px;
        height: 20px;

        &.zhengque {
            background-image: url("@/assets/img/public/icon-zhengque.png");
            background-size: 100%;
            background-repeat: no-repeat;
        }

        &.cuowu {
            background-image: url("@/assets/img/public/icon-cuowu.png");
            background-size: 100%;
            background-repeat: no-repeat;
        }
        &.alert {
          background-image: url("@/assets/img/public/icon_alert.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
    }

    p {
      flex: 1;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      color: #EFF0F3;
      letter-spacing: 0;
    }
  }

  .hm-message-fade-enter,
  .hm-message-fade-leave-active {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
</style>
