import Vue from 'vue';
import Main from './main.vue';
let HandmadeMessageConstructor = Vue.extend(Main);

let instance,
  instances = [],
  seed = 1;

const HandmadeMessage = function (options, event) {
  if (Vue.prototype.$isServer) return;
  options = options || {};
  if (typeof options === 'string') {
    options = {
      message: options
    };
  }
  let userOnClose = options.onClose;
  let id = 'hm-message_' + seed++;

  options.onClose = function () {
    HandmadeMessage.close(id, userOnClose);
  };
  instance = new HandmadeMessageConstructor({
    data: options
  });
  instance.id = id;
  instance.vm = instance.$mount();
  document.body.appendChild(instance.vm.$el);
  instance.vm.visible = true;
  instance.dom = instance.vm.$el;
  instance.dom.style.zIndex = 5000;
  instance.dom.style.top = options.top || '50%';
  instances.push(instance);
  return instance.vm;
};

['success', 'warning', 'info', 'error'].forEach(type => {
  HandmadeMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.type = type;
    return HandmadeMessage(options);
  };
});

HandmadeMessage.close = function (id, userOnClose) {
  for (let i = 0, len = instances.length; i < len; i++) {
    if (id === instances[i].id) {
      if (typeof userOnClose === 'function') {
        userOnClose(instances[i]);
      }
      instances.splice(i, 1);
      break;
    }
  }
};

HandmadeMessage.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close();
  }
};

export default HandmadeMessage;
