<template>
  <div class="input-search-block" :class="[newTheme]">
    <img alt src="../../assets/img/input-search.svg" class="search-img" />
    <input type="password" name="fundpassword" style="display:none; width:0; height:0"/>
    <input
      class="search-input"
      :placeholder="$t('index.placeholder_search')"
      :value="searchText"
      @input="onChangeText"
      autocomplete="off"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['searchText'],
  computed: {
    ...mapState(['newTheme'])
  },
  methods: {
    onChangeText(e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.input-search-block {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  height: 36px;
  padding: 10px 12px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: @black-bg-input;
  .search-input {
    border: none;
    width: 100%;
    height: 30px;
    background-color: transparent;
    border: none;
    font-size: 12px;
    .regular-font();
    font-weight: 600;
    color: @black-text-primary;
    margin-left: 8px;
  }
  .search-input::input-placeholder,
  .search-input::placeholder {
    color: @black-text-tertiary;
    font-size: 12px;
    .regular-font();
    font-weight: 400;
    text-align: left;
    color: red;
  }
  .search-input::-webkit-input-placeholder,
  .search-input::-webkit-placeholder {
    color: red;
    font-size: 12px;
    .regular-font();
    font-weight: 400;
    text-align: left;
  }
  input::placeholder,
  input::-webkit-input-placeholder,
  input:-moz-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder {
    color: green !important;
  }
}
.white {
  .input-search-block {
    background-color: @white-bg-input;
    .search-input {
      color: @white-text-primary;
    }
    input::placeholder {
      color: @white-text-tertiary;
    }
    ::-webkit-input-placeholder {
      color: @white-text-tertiary;
    }
  }
}
</style>