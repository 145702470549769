<template>
  <div class="search-currency-block" :class="[newTheme]">
    <InputSearch :searchText="searchText" @input="onChangeSearch" />
    <SearchContent :searchText="searchText" :showSpot="true" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InputSearch from './InputSearch.vue';
import SearchContent from './SearchContent.vue';

export default {
  data() {
    return {
      searchText: ''
    };
  },
  computed: {
    ...mapState(['newTheme', 'locale']),
    ...mapGetters(['isLogin'])
  },
  components: {
    InputSearch,
    SearchContent
  },
  methods: {
    onChangeSearch(val) {
      this.searchText = val;
    }
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.search-currency-block {
  min-width: 480px;
  height: 628px;
  border-radius: 16px;
  background-color: @black-bg-secondary;
  border: 1px solid @black-bg-secondary;
  position: relative;
}
.white.search-currency-block{
  background-color: @white-bg-secondary;
  border: 1px solid @white-bg-secondary;
}
</style>