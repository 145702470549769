<template>
	<!-- <div>
		<img src="~/assets/img/header/icon_close.svg">
	</div> -->
	<div class="mask" @click="closeMenu()">
		<div class="hamberger_list">
			<div class="menu_header" @click.stop="closeMenu()">
				<img class="close" src="~/assets/img/header/icon_close.svg">
			</div>
			<section class="menu_body">
				<div class="newRegister_btn" @click="globalRegister()">
					{{ $t("new_index.adv.reg_now") }}
				</div>
				<div class="hamberger_item" @click.stop="lang_sub = !lang_sub">
					<div>
						{{ $t("new_index.header.language_tit") }}
					</div>
					<div>
						<img v-if="!lang_sub" :class="['triangle']" src="~/assets/img/header/icon_more.svg">
						<img v-else :class="['triangle']" src="~/assets/img/header/icon_open.svg">
					</div>
				</div>
				<ul class="hamberger_item_wrap" v-if="lang_sub">
					<li
						class="hamberger_item_sub"
						:class="{ lang_yellow: locale == 'zh-CN' }"
						@click.stop="switchLang('zh-CN', 1)"
					>
						简体中文
					</li>
					<li
						class="hamberger_item_sub"
						:class="{ lang_yellow: locale == 'zh-TW' }"
						@click.stop="switchLang('zh-TW', 5)"
					>
						繁体中文
					</li>
					<li
						class="hamberger_item_sub"
						:class="{ lang_yellow: locale == 'en' }"
						@click.stop="switchLang('en', 0)"
					>
						English
					</li>
					<li
						class="hamberger_item_sub"
						:class="{ lang_yellow: locale == 'ko' }"
						@click.stop="switchLang('ko', 3)"
					>
						한국어
					</li>
					<li
						class="hamberger_item_sub"
						:class="{ lang_yellow: locale == 'vi' }"
						@click.stop="switchLang('vi', 4)"
					>
						Tiếng Việt
					</li>
				</ul>
				<!-- <div class="hamberger_item" @click.stop="copy_sub = !copy_sub">
					{{ $t("link.copy_trade") }}
					<div>
						<img v-if="!copy_sub" :class="['triangle']" src="~/assets/img/header/icon_more.svg">
						<img v-else :class="['triangle']" src="~/assets/img/header/icon_open.svg">
					</div>
				</div> -->
				<!-- <ul class="hamberger_item_wrap" v-if="copy_sub">
					<li
						class="hamberger_item_sub"
						@click="goto('topTraderCompetition')"
					>
						{{ $t("link.top-trader-competition") }}
					</li>
					<li class="hamberger_item_sub" :class="{ active: route.indexOf('documentary')!=-1 }" @click="goto('documentary')">
						{{ $t("copy_trader.nav") }}
					</li>
				</ul> -->
				<nuxt-link class="hamberger_item" :to="$i18n.path('affiliate')" :class="{ active: route.indexOf('affiliate')!=-1 }">
					{{ $t("new_index.header.partner") }}
				</nuxt-link>
				<nuxt-link v-if="locale==='en'" class="hamberger_item" :to="$i18n.path('lunc-burn')" :class="{ active: route.indexOf('lunc-burn')!==-1 }">
					Lunc Burn
				</nuxt-link>
				<a v-if="locale=='en' || locale=='ko' || locale=='vi'" class="hamberger_item blog" :href="$t('links.reward_link')" target="_blank">
					{{ $t("links.get_reward") }}
					<img class="fire" src="~assets/img/new_index/icon_nav_hot2.svg">
				</a>
				<!-- TODO: 第一期隐藏，后期放开 -->
				<!-- launchpad -->
				<!-- <div class="hamberger_item launchpad" :class="{ active: route.indexOf('Launchpool')!=-1 }" @click="goto('Launchpool')">
					Launchpool
					<img alt="" src="~assets/img/new_index/hot.svg" class="img-hot" />
				</div> -->
				<nuxt-link class="hamberger_item" :to="$i18n.path('protectfund')" :class="{ active: route.indexOf('protectfund')!=-1 }">
					{{ $t("links.fund") }}
				</nuxt-link>
				<nuxt-link v-if="locale!='ko' && locale!='en'" :to="$i18n.path('spokesperson')" class="hamberger_item" :class="{ active: route.indexOf('spokesperson')!=-1 }">
					{{ $t("spokesperson.nav_short") }}
				</nuxt-link>
				<nuxt-link class="hamberger_item" :to="$i18n.path('community')" :class="{ active: route.indexOf('community')!=-1 }">
					{{ $t("global.title") }}
				</nuxt-link>
				<nuxt-link class="hamberger_item"  :to="$i18n.path('global-strategy')" :class="{ active: route.indexOf('global-strategy')!=-1 }">
					{{ $t("new_index.header.title.strategy") }}
				</nuxt-link>
				<!-- <div class="hamberger_item" @click="gotJoinIn()">
					{{ $t("link.join us") }}
				</div>
				<div class="hamberger_item" @click="goto('about-us')">
					{{ $t("link.about us") }}
				</div> -->
				<a v-if="locale!='en' && locale!='ko'" class="hamberger_item blog" href="https://blog.weex.com" target="_blank">
					{{ $t("links.blog") }}
					<img class="fire" src="~assets/img/new_index/icon_nav_hot2.svg">
				</a>
				<!-- <div class="hamberger_item" :class="{ active: route.indexOf('job')!=-1 }" @click="goto('job')">
					{{ $t("career_opportunities.career_opportunities") }}
				</div> -->
				<!-- <div v-if="locale!='en' && locale!='zh-TW'" class="hamberger_item" :class="{ active: route.indexOf('news-report')!=-1 }" @click="goto('news-report')">
					{{ $t("link.news-report") }}
				</div> -->
				<nuxt-link class="hamberger_item" :class="{ active: route.indexOf('official-verification')!=-1 }" :to="$i18n.path('official-verification')">
					{{ $t("new_index.header.title.official") }}
				</nuxt-link>
				<!-- <div v-if="locale=='zh-CN'" class="hamberger_item" :class="{ active: route.indexOf('school')!=-1 }" @click="goto('school')">
					{{ $t("new_index.weex_course") }}
				</div> -->
				<nuxt-link class="hamberger_item" :class="{ active: route.indexOf('documentary')!=-1 }" :to="$i18n.path('documentary')">
					{{ $t("copy_trader.nav") }}
				</nuxt-link>
				<div class="hamberger_item hide" @click.stop="switchMode()">
					<div>
						{{ $t("link.night_mode") }}
					</div>
					<div>
						<img v-if="newTheme==='dark'" class="mode" src="~/assets/img/header/night.svg">
						<img v-else class="mode" src="~/assets/img/header/sun.svg">
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			locale: "",
			lang_sub: false,
			copy_sub: false,
			night_mode: false,
			route: location.href
		}
	},
	computed: {
		newTheme() {
			return this.$store.state.theme;
		}
	},
	methods: {
		globalRegister(){
			this.$router.push(this.$i18n.path("register"));
		},
		switchLang(lang, type) {
			//this.$i18n.locale = lang
			var reg = new RegExp("^/" + lang + "/");
			if (reg.test(this.$route.fullPath)) {
				return;
			}
			console.log("locale:",this.locale);
			if(this.locale === 'en') {
				//console.log("a01", `/${lang}${location.pathname}`)
				this.$router.push(`/${lang}${location.pathname}`);
			} else {
				//console.log("a02", this.$route.fullPath.replace(/^\/[^\/]+/, `/${lang}`))
				this.$router.push(this.$route.fullPath.replace(/^\/[^\/]+/, `/${lang}`));
			}
			this.locale = lang;
		},
		goto(path) {
			this.$router.push(this.$i18n.path(path));
		},
		open(path) {
			window.open(path);
		},
		gotJoinIn() {
			let href =
				"https://weexsupport.zendesk.com/hc/zh-cn/sections/4487095693593-WEEX%E4%BA%BA%E6%89%8D%E6%8B%9B%E8%81%98%E4%B8%93%E5%8C%BA";
			window.open(href);
		},
		// regMod() {
		//   this.$parent.showDownloadApp();
		// },
		regMod() {
			document.body.style.overflow = "hidden"; //開啟彈窗禁止scroll
			this.$refs.dialog.showDialog();
		},
		switchMode() {
			(this.newTheme=='dark')?
				this.$store.commit('SET_THEME', 'light'):
				this.$store.commit('SET_THEME', 'dark')
		},
		closeMenu() {
			this.$emit("update", false);
			document.body.style.overflow = "scroll"; //關閉彈窗恢復scroll
		}
	},
	beforeDestroy() {
		document.body.style.overflow = "scroll"; //關閉彈窗恢復scroll
	},
	mounted(){
		this.locale = this.$i18n.locale;
		document.body.style.overflow = "hidden"; //關閉彈窗恢復scroll
	}
};
</script>

<style lang="less">
@media screen and (max-width: 768px) {
	.mask {
		position: relative;
		background: rgba(15, 17, 21, 0.6);
		// width: 37.5rem;
		.pxToVwH5(height, 2000);
		z-index: 9;
	}
	@keyframes MoveToLeft {
		from { left: 100%; }
		to { left: 20%; }
	}
	.hamberger_list {
		position: absolute;
		background: #202228;
		background-repeat: no-repeat;
		background-size: cover;
		.pxToVwH5(top, 0);
		.pxToVwH5(right, 0);
		.pxToVwH5(width, 600);
		height: 100%;
		display: flex;
		flex-direction: column;
		// overflow-y: scroll;
		// overflow-x: hidden;
		overflow: hidden;
		z-index: 9999;
		.pxToVwH5(font-size, 30);
		/* border: 0.05rem solid #333B47; */
		/* animation 參數設定 */
		animation-name: MoveToLeft;    /*動畫名稱，需與 keyframe 名稱對應*/
		animation-duration: 0.3s;    /*動畫持續時間，單位為秒*/
		animation-delay: 0s;    /*動畫延遲開始時間*/
		animation-iteration-count: 1;    /*動畫次數，infinite 為無限次*/

		.close {
			.pxToVwH5(width, 42);
			float: right;
			position: absolute;
			.pxToVwH5(top, 34);
			.pxToVwH5(right,24);
		}
		.menu_header {
			.pxToVwH5(width, 750);
			.pxToVwH5(height, 150);
			box-sizing: border-box;
		}
		.menu_body{
			.pxToVwH5(max-height, 1200);
			overflow: auto;
			flex: 1;
		}
		.newRegister_btn{
			.pxToVwH5(width,540);
			.pxToVwH5(height, 90);
			background: @yellow-P1;
			.pxToVwH5(border-radius, 12);
			color: @text-black;
			.pxToVwH5(line-height, 90);
			text-align: center;
			.pxToVwH5(font-size, 28);
			.medium-font();
			font-weight: bold;
			.pxToVwH5(margin-bottom, 60);
			.pxToVwH5(margin-left, 30);
		}
		.hamberger_item {
			.pxToVwH5(height, 100);
			.pxToVwH5(line-height, 100);
			.pxToVwH5Padding(0, 30);
			z-index: 9999999;
			color: @text-white;
			.medium-font();
			display: flex;
			justify-content: space-between;
			&.launchpad{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				background-color: transparent;
				.img-hot {
					.pxToVwH5(width, 64);
					.pxToVwH5(height, 32);
					.pxToVwH5(margin-left, 16);
				}
			}
			.hot{
				.pxToVwH5(padding, 6);
				.pxToVwH5(border-radius, 4);
				.pxToVwH5(font-size, 20);
				.pxToVwH5(margin-left, 16);
				background-color: #EC4551;
				color: @text-white;
			}
			&.blog {
				justify-content: unset;
			}
			&.active {
				color: @yellow-P3;
			}
			&.hide {
				display: none;
			}
		}
		.fire {
			.pxToVwH5(margin-left, 10);
			.pxToVwH5(margin-top, 30);
			.pxToVwH5(width, 35);
			.pxToVwH5(height, 35);
		}
		.triangle {
			.pxToVwH5(width, 28);
			.pxToVwH5(height, 28);
			.pxToVwH5(margin-top, 24);
		}
		.mode {
			.pxToVwH5(width, 82);
			.pxToVwH5(height, 44);
			.pxToVwH5(margin-top, 30);
		}
		.hamberger_item_wrap {
			.hamberger_item_sub {
				/* position: relative; */
				.pxToVwH5(right, 20);
				.pxToVwH5(height, 66);
				.pxToVwH5(line-height, 66);
				color:  @gray-P1;
				.pxToVwH5(padding-left, 92);
				.pxToVwH5(font-size, 28);
				list-style: none;
				&.active {
					color: @yellow-P3;
				}
			}
			.lang_yellow {
				color: @yellow-P3;
			}
		}
	}
}
</style>
