<template>
  <div class="label-type" :class="[newTheme]">{{ text }}</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['text'],
  computed: {
    ...mapState(['newTheme'])
  }
};
</script>

<style lang="less" scoped>
.label-type {
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: 700;
  color: @black-text-primary;
  .bold-font();
}
.white .label-type {
  color: @white-text-primary;
}
</style>